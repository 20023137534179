import Vue from "vue";
import VueRouter from "vue-router";

import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/data",
    name: "Data",
    component: () => import("../views/Data.vue"),
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/request_data",
    name: "Request Data",
    component: () => import("../views/Request_data.vue"),
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/upload",
    name: "Upload",
    component: () => import("../views/Upload.vue"),
  },
  {
    path: "/plans",
    name: "Approved Analysis Plans",
    component: () => import("../views/Plans.vue"),
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/admin",
    name: "Admin",
    component: () => import("../views/Admin.vue"),
    meta: {
      authRequired: true,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

//check if needs to be logged in
router.beforeEach((to, from, next) => {
  store.dispatch("fetchUserProfile");

  if (to.matched.some((record) => record.meta.authRequired)) {
    if (store.state.userProfile && store.state.userProfile.id) {
      next();
    } else {
      next({
        path: "/login",
      });
    }
  } else {
    next();
  }
});

export default router;
